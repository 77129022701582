var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "wrap" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "desc-404" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("OOPS!")]),
          _c("div", { staticClass: "headline" }, [
            _vm._v(" 好像来到了错误的地方... ")
          ]),
          _c("div", { staticClass: "info" }, [
            _vm._v(" 请检查您访问的URL是否正确，或者点击返回按钮回到首页 ")
          ]),
          _c(
            "router-link",
            { attrs: { to: "/" } },
            [
              _c(
                "el-button",
                { staticClass: "back-btn", attrs: { type: "primary" } },
                [_vm._v(" 返回首页 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pic-404" }, [
      _c("img", {
        staticClass: "pic-404__parent",
        attrs: { src: require("@/assets/404_images/404.png"), alt: "404" }
      }),
      _c("img", {
        staticClass: "pic-404__child left",
        attrs: { src: require("@/assets/404_images/404_cloud.png"), alt: "404" }
      }),
      _c("img", {
        staticClass: "pic-404__child mid",
        attrs: { src: require("@/assets/404_images/404_cloud.png"), alt: "404" }
      }),
      _c("img", {
        staticClass: "pic-404__child right",
        attrs: { src: require("@/assets/404_images/404_cloud.png"), alt: "404" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }